import { format as fnsFormat, isValid } from 'date-fns';

// date-fns format tokens differ from Moment.js and other libraries
// https://github.com/date-fns/date-fns/blob/main/docs/unicodeTokens.md
const DATE_FORMAT = 'yyyy-MM-dd';
const TIME_FORMAT = 'HH:mm:ss';
const DATETIME_FORMAT = `${DATE_FORMAT} ${TIME_FORMAT}`;

const createFormatter =
    (format: string) =>
    (dateOrIsoString: Date | string | null): string | null => {
        if (dateOrIsoString === null) return null;

        const date = new Date(dateOrIsoString);
        if (!isValid(date)) return null;
        return fnsFormat(date, format);
    };

export const formatDate = createFormatter(DATE_FORMAT);

export const formatTime = createFormatter(TIME_FORMAT);

export const formatDatetime = createFormatter(DATETIME_FORMAT);

export const formatDatetimeIso = (
    dateOrIsoString: Date | string | null,
): string | null => {
    if (dateOrIsoString === null) return null;

    const date = new Date(dateOrIsoString);
    if (!isValid(date)) return null;
    return date.toISOString();
};

export const parseDate = (dateOrStr: string | null) => {
    if (dateOrStr === null) return null;

    const date = new Date(dateOrStr);
    if (!isValid(date)) return null;
    return date;
};
